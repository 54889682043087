import {
  RequestMethod,
  RequestName,
  RequestProxy,
  useCaller,
} from '~/server/bridges';

export class MonitoringService {
  async sendLog(body = {}) {
    try {
      return await useCaller(
        RequestProxy.NEURON_API,
        RequestName.SEND_LOG,
        'logs',
        RequestMethod.POST,
        {},
        body,
      );
    } catch (e) {
      return {};
    }
  }
}
