import { useContact, usePages } from '~/composables/hydration';
import { useContactsStore } from '~/units/contacts/store';
import { useWebsiteStore } from '~/units/website/store';

export function isRequestNecessary(url: string | undefined) {
  const routeMatches = ['template', 'css'];

  return !routeMatches.some((match) => url?.includes(match));
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server && isRequestNecessary(to.path)) {
    const app = useNuxtApp();

    if (
      app.ssrContext &&
      app.ssrContext.event &&
      app.ssrContext.event.context &&
      app.ssrContext.event.context.sitemap &&
      app.ssrContext.event.context.sitemap.pages
    ) {
      const websiteStore = useWebsiteStore(app.$pinia);
      websiteStore.setTheme(app.ssrContext?.event.context.layout);
      websiteStore.setSitemap(app.ssrContext?.event.context.sitemap);

      usePages();
      useContact();
      websiteStore.setCompany(app.ssrContext.event.context.company);

      if (app?.ssrContext?.event?.context?.sessions) {
        const contactsStore = useContactsStore(app.$pinia);
        contactsStore.setSessionToStore(
          app?.ssrContext?.event?.context?.sessions['neuron-touch'],
        );
        if (app.ssrContext.event.context.contact) {
          contactsStore.setContactInfo(app.ssrContext.event.context.contact);
        }
      }
    }
  } else if (process.client && isRequestNecessary(to.path)) {
  }
});
