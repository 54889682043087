import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/vercel/path0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_client_j7wLaRg9eH from "/vercel/path0/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import plugin_w2VlvAEcpa from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import globalMixins_plugin_09c2kq3z3X from "/vercel/path0/plugins/globalMixins.plugin.ts";
import toastClientNotification_plugin_client_gZplT3wotd from "/vercel/path0/plugins/toastClientNotification.plugin.client.ts";
import vercelAnalytic_client_Jlq8v0CiPA from "/vercel/path0/plugins/vercelAnalytic.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  plugin_client_j7wLaRg9eH,
  plugin_w2VlvAEcpa,
  chunk_reload_client_UciE0i6zes,
  globalMixins_plugin_09c2kq3z3X,
  toastClientNotification_plugin_client_gZplT3wotd,
  vercelAnalytic_client_Jlq8v0CiPA
]