export class ViewTypes {
  static HOME = 'HOME';
  static SELLERS = 'HOME_VENDOR';
  static BLOGS = 'BLOGS';
  static BLOG_DETAIL = 'BLOG_DETAIL';
}

export class LayoutTypes {
  static HEADER = 'HEADER';
  static FOOTER = 'FOOTER';
}

export class MenuTypes {
  static HEADER = 'header';
  static FOOTER = 'footer';

  static HEADER_MENU = 'HEADER_MENU';
  static FOOTER_MENU = 'FOOTER_MENU';
}

export const NOT_FOUND_IMAGE = '/cdn/content/no-image.png';

export const ROUTES = {
  BLOGS: '/blogs',
  PROPERTIES: '/properties',
  CONTACT_US: '/contact-us',
};
