<template>
  <div>website not found</div>
</template>

<script lang="ts">
import { useMonitoringStore } from '~/units/monitoring/store';

export default defineNuxtComponent({
  async setup() {
    if (process.server) {
      const url = useRequestURL();
      const headers = useRequestHeaders();

      if (
        url.searchParams.get('message') === 'WEBSITE_NOT_FOUND' ||
        url.pathname === '/__nuxt_error'
      ) {
        const monitoringStore = useMonitoringStore();

        await monitoringStore.sendLog({
          meta: {
            status_code: 404,
            path: url.searchParams.get('url'),
            origin: url.origin,
            host: url.host,
            headers: {
              'user-agent': headers['user-agent'],
            },
          },
        });
      } else if (url.pathname !== '/not-found') {
        const monitoringStore = useMonitoringStore();

        await monitoringStore.sendLog({
          meta: {
            status_code: 404,
            path: url.pathname,
            origin: url.origin,
            host: url.host,
            headers: {
              'user-agent': headers['user-agent'],
            },
          },
        });
        await navigateTo({
          path: '/not-found',
          query: {
            path: url.pathname.replaceAll('/', ''),
          },
        });
      }
    }
  },
});
</script>
